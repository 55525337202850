<template>
  <div style="background: #ffffff;border-radius: 8px;padding: 12px 24px 24px 24px">
    <div class="fontSize20gay text-left">硬盘录像机编辑</div>
    <div style="border-bottom: 1px solid #EBEBEB;border-top: 1px solid #EBEBEB;margin-top: 16px;padding: 36px 0">
      <div style="width: 800px;margin: auto">
        <el-form :model="pageForm" :rules="pageFormRules" ref="formRef">
          <el-form-item :label="pageForm.type == 0 ? '硬盘录像机名称' : '行为分析服务器名称'" prop="name" size="large" label-width="150px">
            <el-input v-model="pageForm.name"
                      autocomplete="off"
                      placeholder="请填写名称"
                      class="room-input-tl" />
          </el-form-item>
          <el-form-item label="账户" prop="username" size="large" label-width="150px">
            <el-input v-model="pageForm.username"
                      autocomplete="off"
                      placeholder="请填写账户"
                      class="room-input-tl" />
          </el-form-item>
          <el-form-item label="密码" prop="password" size="large" label-width="150px">
            <el-input v-model="pageForm.password"
                      type="password"
                      autocomplete="new-password"
                      placeholder="请填写密码"
                      class="room-input-tl" />
          </el-form-item>
          <el-form-item label="ip" prop="ip" size="large" label-width="150px">
            <el-input v-model="pageForm.ip"
                      autocomplete="off"
                      placeholder="请填写ip"
                      class="room-input-tl" />
          </el-form-item>
          <el-form-item label="端口" prop="port" size="large" label-width="150px">
            <el-input v-model="pageForm.port"
                      autocomplete="off"
                      placeholder="请填写端口号"
                      class="room-input-tl" />
          </el-form-item>

          <el-form-item label="类型" prop="type" size="large" label-width="150px">
            <el-select v-model="pageForm.type"
                       class="room-select-tl"
                       placeholder="选择类型"
                       size="large">
              <el-option label="硬盘录像机" :value="0" />
              <el-option label="行为分析服务器" :value="1" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="marginTop15px">
      <button class="subBtn" @click="subData">保存</button>
      <button class="qxBtn" @click="qxBtn">取消</button>
    </div>
  </div>
</template>

<script>
import {addEquipmentVideoRecorder, editEquipmentVideoRecorder, queryEquipmentVideoRecorderById} from "@/api/monitor";
export default {
  name: "hardDiskEdit",
  data(){
    return {
      classificationOptions: [],
      pageForm: {
        // 硬盘录像机 / 行为分析服务器名称
        name: '',
        // 账户名
        username: '',
        // 密码
        password: '',
        // ip
        ip: '',
        // 端口
        port: '',
        // 0->硬盘录像机 1->行为分析服务器
        type: 0,
      },
      pageFormRules: {
        name: [{ required: true, message: '请填写', }],
        username: [{ required: true, message: '请填写账户', }],
        password: [{ required: true, message: '请填写密码', }],
        ip: [{ required: true, message: '请填写ip', }],
        port: [{ required: true, message: '请填写端口', }],
        type: [{ required: true, message: '请选择类型', },],
      },
      id: null,
    }
  },
  watch: {

  },
  mounted() {
    const id = this.$route.params.id
    if (id) {
      this.id = id
      this.getQuestionInfo()
    }
  },
  methods: {
    getQuestionInfo(){
      queryEquipmentVideoRecorderById(this.id).then(res => {
        if (res.success) {
          const { name, username, ip, port, type } = res.data
          this.pageForm = { name, username, ip, port, type }
        }
      })
    },
    /**
     * 添加选项
     */
    addOptions(){
      const sort = this.pageForm.optionList.length
      this.pageForm.optionList.push({
        // 是否答案
        isAnswer: false,
        // 选项名称
        title: '',
        // 选项
        sort: sort,
      })
    },
    /**
     * 删除选项
     */
    removeOption(index){
      this.pageForm.optionList.splice(index, 1)
      this.pageForm.optionList.map( (iter, index) => { iter.sort = index })
      this.pageForm.correctValue = ''
    },
    /**
     * 设置正确答案
     */
    setCorrect(index){
      this.pageForm.optionList.map(iter => {
        iter.isAnswer = false
      })
      this.pageForm.optionList[index].isAnswer = true

      this.$refs.formRef.validateField('correctValue')
    },
    indexMapper(index){
      return String.fromCharCode(index + 65)
    },
    subData(){
      this.$refs.formRef.validate((valid, error) => {
        if (valid) {
          // 提交类型
          const handler = this.id ? editEquipmentVideoRecorder : addEquipmentVideoRecorder
          if (this.id) {
            this.pageForm.id = this.id
          }

          handler(this.pageForm).then(res => {
            if(res.success){
              this.$root.ElMessage({type:'success',message:res.message});
              setTimeout(() => {
                this.$root.useRouter.replace('/monitor/hardDisk')
              }, 500)
            }else{
              this.$root.ElMessage.error(res.message);
            }
          })
        } else {
          const key = Object.keys(error)[0]
          const msg = error[key][0].message
          this.$root.ElMessage.error(msg)
        }
      })
    },
    qxBtn(){
      this.$root.useRouter.go(-1);
    },
  }
}
</script>

<style lang="scss" scoped>

</style>